import { createContext } from 'react';
import UserTypeEnum from '@constants/user-types.enum';

export interface RedirectType {
  redirectTo: string;
  origin?: UserTypeEnum;
}

export type RedirectContextType = [RedirectType, (RedirectType: RedirectType) => void];

const RedirectContext = createContext<RedirectContextType>([{ redirectTo: '', origin: undefined }, () => {}]);

export default RedirectContext;
