import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import Image from 'next/legacy/image';
import Globe from '@assets/svgs/globe.svg';
import { useRouter } from 'next/router';
import styles from './LanguageToggle.module.scss';

const LanguageToggle = () => {
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const { locale } = router;
  const [visible, setVisible] = useState(false);

  const enFlagImg = (
    <Image width="20" height="15" src="/assets/images/flag-en.png" alt="english flag" data-testid="english-flag" />
  );
  const elFlagImg = (
    <Image width="20" height="15" src="/assets/images/flag-el.png" alt="greek flag" data-testid="greek-flag" />
  );

  /**
   * Function that switches the locale while
   * preserving all routing information
   * see https://nextjs.org/docs/advanced-features/i18n-routing#transition-between-locales
   * @param key
   */
  const changeLanguage = ({ key }: any) => {
    document.cookie = `NEXT_LOCALE=${key}; path=/`;
    router.push({ pathname, query }, asPath, { locale: key });
  };

  const LanguageOptions = (): JSX.Element => {
    return (
      <Menu
        className={`language-toggle-menu ${styles.langOptions}`}
        data-testid="language-menu"
        onSelect={changeLanguage}
        selectable
        selectedKeys={[locale as string]}
      >
        <Menu.Item className={styles.langOption} key="en" data-testid="en-language-item">
          <div className={styles.langOptionItem}>
            {enFlagImg}
            <span>English</span>
          </div>
        </Menu.Item>
        <Menu.Item className={styles.langOption} key="el" data-testid="el-language-item">
          <div className={styles.langOptionItem}>
            {elFlagImg}
            <span>Ελληνικά</span>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Dropdown
      dropdownRender={() => <LanguageOptions />}
      overlayClassName={styles.dropdownOverlay}
      trigger={['click']}
      onOpenChange={() => setVisible(!visible)}
      open={visible}
      placement="bottomRight"
    >
      <div className={styles.languageDropdown} data-testid="language-toggle">
        <Globe />
        <div className={styles.dropdownLine} />
        <div className={styles.dropdownCurrLang} data-testid="current-language">
          {locale === 'el' ? elFlagImg : enFlagImg}
        </div>
      </div>
    </Dropdown>
  );
};

export default LanguageToggle;
