import UserTypeEnum from '@constants/user-types.enum';
import RoutesEnum, { DefaultRoutesEnum } from '@constants/routes.enum';
import { RedirectType } from '@contexts/RedirectContext';
import { ParsedUrlQuery } from 'querystring';

/**
 * Removes the empty/falsy values from an object
 * @param obj
 */
export function removeEmpty(obj: any): any {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => !!v));
}

/**
 * Check whether a string contains another one.
 * @param str type of @string
 * @param substring type of @string
 * @returns type of @boolean
 */
export function isStringIncludesSubstring(str: string, substring: string): boolean {
  return str.indexOf(substring) !== -1;
}

/**
 * Checks if the given pathname contains the word 'employer' and returns a boolean
 * @param pathname type of @string
 * @return type of @boolean
 */
export function isEmployersPath(pathname: string): boolean {
  return isStringIncludesSubstring(pathname, UserTypeEnum.EMPLOYER);
}

/**
 * Takes as param the default route and a boolean describing if the user is employer and constructs the pathname to go
 * The redirectTo url is returned if:
 * - redirect param was given,
 * - redirectTo exists
 * - origin is the same with the route (employer | jobseeker),
 * - the given pathname is HOME_PAGE
 * @param pathname type of @DefaultRoutesEnum
 * @param isEmployer type of @boolean
 * @param redirect type of @RedirectType
 */
export function getPathToGo(
  pathname: DefaultRoutesEnum,
  isEmployer: boolean,
  redirect?: RedirectType,
): RoutesEnum | string {
  const route = isEmployer ? UserTypeEnum.EMPLOYER.toUpperCase() : UserTypeEnum.JOBSEEKER.toUpperCase();
  if (
    redirect &&
    redirect.redirectTo &&
    pathname === DefaultRoutesEnum.HOME_PAGE &&
    redirect.origin === route.toLowerCase()
  ) {
    return redirect.redirectTo;
  }
  return RoutesEnum[`${route}_${pathname}` as keyof typeof RoutesEnum];
}

/**
 * Returns a RedirectType with the redirectTo url and the origin when the search param exists,
 * otherwise it returns undefined
 * @param query type of @ParsedUrlQuery
 */
export const getRedirectQuery = (query: ParsedUrlQuery): RedirectType | undefined => {
  const { redirectTo } = query;

  if (redirectTo) {
    const { origin } = query;

    const redirectParams = {
      redirectTo: query?.redirectTo as string,
      origin: query?.origin ? (origin as UserTypeEnum) : undefined,
    };

    return removeEmpty(redirectParams);
  }
  return undefined;
};
