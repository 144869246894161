import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import Image from 'next/legacy/image';
import IntroErrorContext from '@contexts/IntroErrorContext';
import UiAlert from '@components/ui/alert/UiAlert';
import LanguageToggle from '@components/ka-ui/language-toggle/LanguageToggle';
import styles from './AuthLayout.module.scss';

type IntroLayoutProps = {
  children: React.ReactNode;
  title: string;
};

const AuthLayout: React.FC<IntroLayoutProps> = ({ children, title }) => {
  const [error, setError] = useContext(IntroErrorContext);
  const { i18n } = useTranslation(['common', 'login']);

  /**
   * Remove the given error from the generic intro errors
   * @param errorMessage type of @string
   */
  const removeError = (errorMessage: string) => {
    return {
      errorMessages: error.errorMessages.filter((err: string) => err !== errorMessage),
    };
  };

  return (
    <div className={`${styles.layout}`}>
      <div className={styles.layoutForm}>
        <header className={styles.layoutFormHeader}>
          <div className={styles.layoutFormHeaderLogo}>
            <Image width={200} height={39} src="/assets/images/hrprior-dark.png" alt="HRprior Logo" />
          </div>
        </header>
        {!!error.errorMessages.length &&
          error.errorMessages.map((errorMessage: string) => (
            <div className={styles.layoutFormHeaderError} key={errorMessage}>
              <UiAlert
                className={styles.layoutFormHeaderErrorAlert}
                type="error"
                message={errorMessage}
                afterClose={() => setError(removeError(errorMessage))}
                data-testid={errorMessage}
              />
            </div>
          ))}
        <div className={styles.wrapAuthHeader}>
          <div className={styles.wrapAuthHeaderWidth}>
            <div className={`h4 ${styles.layoutFormHeaderTitle}`}>{title}</div>
            <div className={styles.layoutFooter}>
              <div className={styles.layoutLanguageContainer}>
                <div />
                <LanguageToggle />
              </div>
            </div>
          </div>
        </div>
        <section className={styles.layoutFormHeaderSection}>{children}</section>
      </div>
      <div className={styles.layoutImgWrapper}>
        {i18n.language === 'en' ? (
          <Image width="1200" height="1920" src="/assets/images/employer-en.jpg" alt="employer" />
        ) : (
          <Image width="1200" height="1920" src="/assets/images/employer-gr.jpg" alt="employer" />
        )}
      </div>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
