import { createContext } from 'react';

export interface IntroError {
  errorMessages: Array<string>;
}

export type IntroErrorContextType = [IntroError, (IntroError: IntroError) => void];

const IntroErrorContext = createContext<IntroErrorContextType>([{ errorMessages: [] as Array<string> }, () => {}]);

export default IntroErrorContext;
