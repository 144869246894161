import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoginCard.module.scss';

type WithChildren<T = {}> = T & { children: React.ReactNode };

type LoginCardProps = WithChildren<{
  title?: string;
  subtitle?: string;
  buttonText?: string;
  previousStep?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}>;

const LoginCard: React.FC<LoginCardProps> = ({ title, subtitle, buttonText, previousStep, children }) => {
  return (
    <article className={styles.compWrap}>
      {(!!title || !!subtitle || !!buttonText) && (
        <header className={styles.header}>
          {title && (
            <h4 className={`h4 ${styles.title}`} data-testid="login-card-header">
              {title}
            </h4>
          )}
          {subtitle && (
            <h5 className={`h5 ${styles.subtitle}`} data-testid="login-card-subheader">
              {subtitle}
            </h5>
          )}
          {buttonText && (
            <div
              role="button"
              onClick={previousStep}
              onKeyDown={() => previousStep}
              tabIndex={0}
              className={`main-body-text ${styles.btn}`}
              data-testid="login-card-link"
            >
              {buttonText}
            </div>
          )}
        </header>
      )}
      <div className={`h4 ${styles.content}`}>{children}</div>
    </article>
  );
};

LoginCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  previousStep: PropTypes.func,
  children: PropTypes.node.isRequired,
};
LoginCard.defaultProps = {
  title: undefined,
  subtitle: undefined,
  buttonText: undefined,
  previousStep: undefined,
};

export default LoginCard;
